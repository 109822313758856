// TODO: Eventual these should be their own react components, not raw jquery strings.
// This is just a bandaid fix to respond to quick changes from the client and move
// their forms over.
const EXAM_RESULT = `
<div id="s-47ce86f3-9330-4bfc-8879-1072a00ba74a" class="shg-c  ">
  <!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.           -->
<!--  ----------------------------------------------------------------------  -->
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
 /*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> 
<script type="text/javascript">
  function recaptcha_callback(){
    $('.button').prop("disabled", false);
  }

  $(document).ready(function() {
    $(".js-support-form__form").on("submit", function(event) {
      var response = $("#g-recaptcha-response").val();
      if(response === '') {
        event.preventDefault();
        alert("Please verify you are human!");
      }
    });
    
    const months = [3, 7, 11]; 

    var selectField = document.getElementById("00N7V000003PW7L");
    var year = (new Date().getFullYear()) - 1;
    
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < months.length; j++) {
        var today = new Date();
        var optionValue = new Date(year+1,months[j]);
        if(today <  optionValue && selectField.length < 7){   
            var option = document.createElement("option");
            var formathDate = new Date(year,months[j]-1);
            var monthName = new Intl.DateTimeFormat("en-US", {month:"long"}).format(formathDate);
            option.text = monthName+" "+year;
            option.value = monthName+" "+ year;

            var isPastDate = (new Date(year,months[j])) < today;
            if(isPastDate){
              option.setAttribute('data-past-date', 1);
            }
 
            selectField.add(option);
        }
      }
      year++;
    }


    var selectMonth = $(selectField);
    var selectNewMonth = $('#00N7V000003PW7Q');
    var selectResult = $('#00N7V000003PW7B');
  
    selectMonth.change(handleMonthChange);
    selectResult.change(handleMonthChange);
  
    function handleMonthChange(){
      var examMonth = selectMonth.val();
      var result = selectResult.val();
  
      //unset new month and hide the select if the first two selects are invalid
      if(examMonth == '' || result == '' || result == 'Pass'){
        selectNewMonth.prop('required', false).val('').hide().parent().hide();
        return;
      }

      //copy the month options to the second month select field
      selectNewMonth.empty();
      selectMonth.children().clone().appendTo(selectNewMonth);
  
      //else show the new month select
      selectNewMonth.prop('required', true).val('').show().parent().show();
  
      //delete the month options that are <= the chosen exam month
      var newMonthOptions = selectNewMonth.children('[value!=""]');
  
      newMonthOptions.each(function(i, option){
      	var option = $(option);
        var value = option.val();
        option.remove();
  
        if(value == examMonth){ 
          return false; //break
        }
      });

      newMonthOptions.filter('[data-past-date="1"]').remove();
    }
    
  });
</script>
<style>
    .support-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    .support-form__row {
      width: 100%;
      margin: 0 10px 30px;
    }
  
    .support-form__row,
    .support-form label {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      line-height: 1.5rem;
      font-weight: 700;
      color: #546277;
      text-transform: uppercase;
      width: 100%;
    }

    .support-form--lowercase{
      font-weight: 400;
      font-style: italic;
      text-transform: none;
    }
    
    .support-form--display{
      display: block;
    }
  
    .support-form input,
    .support-form textarea,
    .support-form select {
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      display: block;
      font-size: 16px;
      padding: 10px;
      border: 1px solid #707070;
    }
  
    .support-form textarea {
      min-height: 50px;
      height: auto;
    }
    
    .support-form__row.support-form--submit{
      margin-top: 30px;
      width: 100%;
    }
    
    .support-form__row.support-form--submit .button{
      width: calc(35% - 20px);
    }
  
    @media (min-width: 992px) {
  
      .support-form--half {
        width: calc(50% - 20px);
      }
      
      .support-form__row.support-form--submit .button{
        width: calc(20% - 20px);
      }
    }
        
    .support-form--hp{
      margin: 0;
      height: 0;
    }

    .hp{
      display: none !important;
    }
  </style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<meta http-equiv="Content-type" content="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST" data-support-form="">

    <input type="hidden" name="orgid" value="00D60000000J4Mx">
    <input type="hidden" name="retURL" value="https://dalton-education.com/thank-you">

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
    <!--  ----------------------------------------------------------------------  -->
    <div class="support-form__row support-form--half">
        <label for="name">Contact Name</label>
        <input id="name" maxlength="80" name="name" size="20" type="text" required="">
    </div>

    <div class="support-form__row support-form--half">
        <label for="email">Email</label>
        <input id="email" maxlength="80" name="email" size="20" type="text" required="">
    </div>

    <div class="support-form__row support-form--half">
        <label for="00N7V000003PW7L">Exam Cycle:</label>
        <select id="00N7V000003PW7L" name="00N7V000003PW7L" required="">
          <option value="">Select Exam Cycle</option>
        </select>
    </div>

    <div class="support-form__row support-form--half">
        <label for="00N7V000003PW7B">Result:</label>
        <select id="00N7V000003PW7B" name="00N7V000003PW7B" title="Result" required="">
          <option value="">Select Result</option>
          <option value="Pass">Pass</option>
          <option data-toggle="Fail" value="Fail">Fail</option>
          <option data-toggle="Did Not Sit" value="Did Not Sit">Did Not Sit</option>
        </select>
    </div>

    <div class="support-form__row support-form--half" hidden="">
        <label for="00N7V000003PW7Q">When do you plan to sit for the exam again?</label>
        <select id="00N7V000003PW7Q" name="00N7V000003PW7Q" title="When do you plan to sit for the exam again?">
        </select>
    </div>

    <div class="support-form__row" hidden="">
        <label class="form__label" for="subject">Subject</label>
        <input id="subject" maxlength="80" name="subject" size="20" type="text" value="Exam Result Reporting" hidden=""><br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="00N0e000003De6b">Company:</label>
        <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
            <option value="Dalton" selected="">Dalton</option>
        </select>
    </div>

    <div class="support-form__row" hidden="">
        <label for="type">Type</label>
        <select id="type" name="type">
            <option value="Result Reporting" selected="">Result Reporting</option>
        </select>
    </div>

    <div class="support-form__row" hidden="">
        <label for="recordType">Case Record Type</label>
        <select id="recordType" name="recordType">
            <option value="0120e000000Dypi" selected="">CeriFi Support</option>
        </select>
    </div>
    <div class="support-form__row" hidden="">
        <label for="priority">Priority:</label>
        <input id="priority" name="priority" value="Medium">
    </div>
    <div class="support-form__row" hidden="">
      <label for="00N7V000003nBJo">Reason:</label>
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
          <option value="CFP Exam" selected="">CFP Exam</option>
      </select>
    </div>
    <div class="support-form__row support-form--half">
        <label for="00N7V000003PW7G">Comments:</label>
        <textarea id="00N7V000003PW7G" name="00N7V000003PW7G" type="text" wrap="soft"></textarea>
    </div>
    <div class="support-form__row">
      <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback"></div>
      <br>
    </div>
    <div class="support-form__row support-form--submit"><input id="formSubmitButton" class="button leaf-button" type="submit" name="submit" data-gtm-vis-recent-on-screen50865725_44="1211" data-gtm-vis-first-on-screen50865725_44="1211" data-gtm-vis-total-visible-time50865725_44="100" data-gtm-vis-has-fired50865725_44="1"></div>
</form>
<div class="support-form__error js-support-form__error" hidden="">Sorry, there was an error with your form submission. Please try again later.</div>
<div class="support-form__loading js-support-form__loading" hidden="">Sending...</div>
<div class="support-form__success js-support-form__success" hidden="">Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>
</div>
`

const TDR_MATERIAL_REQUEST = `<div id="s-47ce86f3-9330-4bfc-8879-1072a00ba74a" class="shg-c "> <script src="https://www.google.com/recaptcha/api.js" async defer></script> <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script> <script>
/*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> <script type="text/javascript">
function recaptcha_callback(){
$('.button').prop("disabled", false);
}

$(document).ready(function() {
   $(".js-support-form__form").on("submit", function(event) {
     var response = $("#g-recaptcha-response").val();
     if(response === '') {
       event.preventDefault();
       alert("Please verify you are human!");
     }
   });
   
   const months = [3, 7, 11]; 

   var selectField = document.getElementById("00N0e000003DexL");
   var year = new Date().getFullYear();
   
   for (let i = 0; i < 3; i++) {
     for (let j = 0; j < months.length; j++) {
       var today = new Date();
       var optionValue = new Date(year,months[j]);
           if(today <  optionValue && selectField.length < 7){   
           var option = document.createElement("option");
           var formathDate = new Date(year,months[j]-1);
           var monthName = new Intl.DateTimeFormat("en-US", {month:"long"}).format(formathDate);
           option.text = monthName+" "+year;
           option.value = monthName+" "+ year;
           selectField.add(option);
       }
     }
     year++;
   }

   //only show address fields if the user clicks the checkbox
   var addressFields = $('#00N0e000003Dex1, #00N0e000003Dex6, #00N0e000003DexB, #00N0e000003DexG');
   var addressDividerDiv = $('#address-fields-divider');
   // var selectMaterialType = $('#00N0e000003Dexa');
   var updateShippingAddress = $('#update-shipping-address');
   updateShippingAddress.change(function(){
     // var type = $(this).val();
     if(this.checked){
       addressFields.val('').prop('required', true).show().parent().show();
       // addressDividerDiv.show();
       return;
     }

     addressFields.val('').prop('required', false).hide().parent().hide();
     // addressDividerDiv.hide();
   });
});

</script> <style>
   .support-form {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
   }
 
   .support-form__row {
     width: 100%;
     margin: 0 10px 30px;
   }
 
   .support-form__row,
   .support-form label {
     font-family: 'Montserrat', sans-serif;
     font-size: 14px;
     line-height: 1.5rem;
     font-weight: 700;
     color: #546277;
     text-transform: uppercase;
     width: 100%;
   }

   .support-form--lowercase{
     font-weight: 400;
     font-style: italic;
     text-transform: none;
   }
   
   .support-form--display{
     display: block;
   }
 
   .support-form input,
   .support-form textarea,
   .support-form select {
     width: 100%;
     font-family: 'Montserrat', sans-serif;
     display: block;
     font-size: 16px;
     padding: 10px;
     border: 1px solid #707070;
   }
 
   .support-form textarea {
     min-height: 50px;
     height: auto;
   }
   
   .support-form__row.support-form--submit{
     margin-top: 30px;
     width: 100%;
   }
   
   .support-form__row.support-form--submit .button{
     width: calc(35% - 20px);
   }
 
   @media (min-width: 992px) {
 
     .support-form--half {
       width: calc(50% - 20px);
     }
     
     .support-form__row.support-form--submit .button{
       width: calc(20% - 20px);
     }
   }
       
   .support-form--hp{
     margin: 0;
     height: 0;
   }

   .hp{
     display: none !important;
   }

   #update-shipping-address{
     display: inline;
     width: unset;
     margin-right: 5px;
   }
</style>

<meta http-equiv="Content-type" content="text/html; charset=UTF-8">
<form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
   <input type="hidden" name="orgid" value="00D60000000J4Mx">
   <input type="hidden" name="retURL" value="https://dalton-education.com/thank-you">
   <div class="support-form__row support-form--half">
      <label for="name">Contact Name</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" required="">
   </div>
   <div class="support-form__row support-form--half">
      <label for="email">Email</label>
      <input id="email" maxlength="80" name="email" size="20" type="text" required="true">
      <span style="font-size: 12px; font-weight: 400">*Please use the email associated with your education account.</span>
   </div>
   <div class="support-form__row support-form--half">
      <label for="phone">Phone</label>
      <input id="phone" maxlength="40" name="phone" size="20" type="text" required="true">
      <br>
   </div>
   <div class="support-form__row support-form--half">
      <label for="00N0e000003DexL">CFP Exam Month?:</label>
      <select id="00N0e000003DexL" name="00N0e000003DexL" required="true">
         <option value="">Select Month</option>
      </select>
   </div>
   <div class="support-form__row support-form--half">
      <label for="00N0e000003DexV">Where will you be attending?:</label> 
      <select id="00N0e000003DexV" name="00N0e000003DexV" required="true">
         <option value="">Select</option>
         <option value="Virtual">Virtual</option>
         <option value="Atlanta">Atlanta</option>
         <option value="Chicago">Chicago</option>
         <option value="LA/Irvine">LA/Irvine</option>
         <option value="Washington D.C.">Washington D.C.</option>
         <option value="New York City">New York City</option>
      </select>
   </div>
   <div class="support-form__row support-form--half"> <label for="00N0e000003Dexf">Additional Information:</label> <textarea id="00N0e000003Dexf" name="00N0e000003Dexf" rows="3" type="text" wrap="soft" required="true"></textarea> </div>
   <div class="support-form__row support-form--half"> <input id="update-shipping-address" maxlength="40" name="update-shipping-address" size="20" type="checkbox"> <label for="update-shipping-address">Do you need to update your physical address?</label> </div>
   <div id="address-fields-divider" class="support-form__row support-form--half support-form--divider">
      <div></div>
   </div>
   <div class="support-form__row support-form--half" hidden=""> <label for="00N0e000003Dex1">Mailing Street Address:</label> <textarea id="00N0e000003Dex1" name="00N0e000003Dex1" type="text" wrap="soft"></textarea> </div>
   <div class="support-form__row support-form--half" hidden=""> <label for="00N0e000003Dex6">City:</label> <input id="00N0e000003Dex6" maxlength="50" name="00N0e000003Dex6" size="20" type="text"> </div>
   <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexB">State:</label> 
      <select id="00N0e000003DexB" name="00N0e000003DexB">
         <option value="">Select</option>
         <option value="AL">Alabama</option>
         <option value="AK">Alaska</option>
         <option value="AZ">Arizona</option>
         <option value="AR">Arkansas</option>
         <option value="CA">California</option>
         <option value="CO">Colorado</option>
         <option value="CT">Connecticut</option>
         <option value="DE">Delaware</option>
         <option value="DC">District Of Columbia</option>
         <option value="FL">Florida</option>
         <option value="GA">Georgia</option>
         <option value="HI">Hawaii</option>
         <option value="ID">Idaho</option>
         <option value="IL">Illinois</option>
         <option value="IN">Indiana</option>
         <option value="IA">Iowa</option>
         <option value="KS">Kansas</option>
         <option value="KY">Kentucky</option>
         <option value="LA">Louisiana</option>
         <option value="ME">Maine</option>
         <option value="MD">Maryland</option>
         <option value="MA">Massachusetts</option>
         <option value="MI">Michigan</option>
         <option value="MN">Minnesota</option>
         <option value="MS">Mississippi</option>
         <option value="MO">Missouri</option>
         <option value="MT">Montana</option>
         <option value="NE">Nebraska</option>
         <option value="NV">Nevada</option>
         <option value="NH">New Hampshire</option>
         <option value="NJ">New Jersey</option>
         <option value="NM">New Mexico</option>
         <option value="NY">New York</option>
         <option value="NC">North Carolina</option>
         <option value="ND">North Dakota</option>
         <option value="OH">Ohio</option>
         <option value="OK">Oklahoma</option>
         <option value="OR">Oregon</option>
         <option value="PA">Pennsylvania</option>
         <option value="RI">Rhode Island</option>
         <option value="SC">South Carolina</option>
         <option value="SD">South Dakota</option>
         <option value="TN">Tennessee</option>
         <option value="TX">Texas</option>
         <option value="UT">Utah</option>
         <option value="VT">Vermont</option>
         <option value="VA">Virginia</option>
         <option value="WA">Washington</option>
         <option value="WV">West Virginia</option>
         <option value="WI">Wisconsin</option>
         <option value="WY">Wyoming</option>
      </select>
   </div>
   <div class="support-form__row support-form--half" hidden=""> <label for="00N0e000003DexG">Zip Code:</label> <input id="00N0e000003DexG" maxlength="50" name="00N0e000003DexG" size="20" type="text"><br> </div>
   <div class="support-form__row" hidden=""> <label class="form__label" for="subject">Subject</label> <input id="subject" maxlength="80" name="subject" size="20" type="text" value="TDR Materials Request" hidden=""><br> </div>
   <div class="support-form__row" hidden="">
      <label for="00N7V000003nBJo">Reason:</label> 
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
         <option value="Course Access" selected="">Course Access</option>
      </select>
   </div>
   <div class="support-form__row" hidden="">
      <label for="00N0e000003De6b">Company:</label> 
      <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
         <option value="Dalton" selected="">Dalton</option>
      </select>
   </div>
   <div class="support-form__row" hidden="">
      <label for="type">Type</label> 
      <select id="type" name="type">
         <option value="My Courses" selected="">My Courses</option>
      </select>
   </div>
   <div class="support-form__row" hidden="">
      <label for="recordType">Case Record Type</label> 
      <select id="recordType" name="recordType">
         <option value="0120e000000Dypi" selected="">CeriFi Support</option>
      </select>
   </div>
   <div class="support-form__row">
      <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback">
      </div>
      <br> 
   </div>
   <div class="support-form__row support-form--submit"> <input class="button leaf-button" type="submit" name="submit"> </div>
</form>
<div class="support-form__error js-support-form__error" hidden="">Sorry, there was an error with your form submission. Please try again later.</div>
<div class="support-form__loading js-support-form__loading" hidden="">Sending...</div>
<div class="support-form__success js-support-form__success" hidden="">Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>
</div>`

const GUARANTEE_TO_PASS_MATERIAL_REQUEST = `<div id="s-47ce86f3-9330-4bfc-8879-1072a00ba74a" class="shg-c  ">
<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.           -->
<!--  ----------------------------------------------------------------------  -->
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
/*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> 
<script type="text/javascript">
function recaptcha_callback(){
$('.button').prop("disabled", false);
}

$(document).ready(function() {
  $(".js-support-form__form").on("submit", function(event) {
    var response = $("#g-recaptcha-response").val();
    if(response === '') {
      event.preventDefault();
      alert("Please verify you are human!");
    }
  });
  
  const months = [3, 7, 11]; 

  var selectField = document.getElementById("00N0e000003DexL");
  var year = new Date().getFullYear();
  
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < months.length; j++) {
      var today = new Date();
      var optionValue = new Date(year,months[j]);
          if(today <  optionValue && selectField.length < 7){   
          var option = document.createElement("option");
          var formathDate = new Date(year,months[j]-1);
          var monthName = new Intl.DateTimeFormat("en-US", {month:"long"}).format(formathDate);
          option.text = monthName+" "+year;
          option.value = monthName+" "+ year;
          selectField.add(option);
      }
    }
    year++;
  }

  //only show address fields if the user clicks the checkbox
  var addressFields = $('#00N0e000003Dex1, #00N0e000003Dex6, #00N0e000003DexB, #00N0e000003DexG');
  var addressDividerDiv = $('#address-fields-divider');
  // var selectMaterialType = $('#00N0e000003Dexa');
  var updateShippingAddress = $('#update-shipping-address');
  updateShippingAddress.change(function(){
    // var type = $(this).val();
    if(this.checked){
      addressFields.val('').prop('required', true).show().parent().show();
      addressDividerDiv.show();
      return;
    }

    addressFields.val('').prop('required', false).hide().parent().hide();
    addressDividerDiv.hide();
  });
});

</script>
<style>
  .support-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .support-form__row {
    width: 100%;
    margin: 0 10px 30px;
  }

  .support-form__row,
  .support-form label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 700;
    color: #546277;
    text-transform: uppercase;
    width: 100%;
  }

  .support-form--lowercase{
    font-weight: 400;
    font-style: italic;
    text-transform: none;
  }
  
  .support-form--display{
    display: block;
  }

  .support-form input,
  .support-form textarea,
  .support-form select {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    display: block;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #707070;
  }

  .support-form textarea {
    min-height: 50px;
    height: auto;
  }
  
  .support-form__row.support-form--submit{
    margin-top: 30px;
    width: 100%;
  }
  
  .support-form__row.support-form--submit .button{
    width: calc(35% - 20px);
  }

  @media (min-width: 992px) {

    .support-form--half {
      width: calc(50% - 20px);
    }
    
    .support-form__row.support-form--submit .button{
      width: calc(20% - 20px);
    }
  }
      
  .support-form--hp{
    margin: 0;
    height: 0;
  }

  .hp{
    display: none !important;
  }

  #update-shipping-address{
    display: inline;
    width: unset;
    margin-right: 5px;
  }
</style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<meta http-equiv="Content-type" content="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">

  <input type="hidden" name="orgid" value="00D60000000J4Mx">
  <input type="hidden" name="retURL" value="https://dalton-education.com/thank-you">

  <!--  ----------------------------------------------------------------------  -->
  <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
  <!--  these lines if you wish to test in debug mode.                          -->
  <!--  <input type="hidden" name="debug" value=1>                              -->
  <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
  <!--  ----------------------------------------------------------------------  -->
  <div class="support-form__row support-form--half">
      <label for="name">Contact Name</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="company">Company</label>
      <input id="company" maxlength="80" name="company" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half">
      <label for="email">Email</label>
      <input id="email" maxlength="80" name="email" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="phone">Phone</label>
      <input id="phone" maxlength="40" name="phone" size="20" type="text" required=""><br>
  </div>
  
  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexL">CFP Exam Month?:</label>
      <select id="00N0e000003DexL" name="00N0e000003DexL" required="">
          <option value="">Select Month</option>
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexV">Where will you be attending?:</label>
      <select id="00N0e000003DexV" name="00N0e000003DexV" required="">
          <option value="">Select</option>
          <option value="Virtual">Virtual</option>
          <option value="Atlanta">Atlanta</option>
          <option value="Chicago">Chicago</option>
          <option value="LA/Irvine">LA/Irvine</option>
          <option value="Washington D.C.">Washington D.C.</option>
          <option value="New York City">New York City</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label class="form__label" for="subject">Subject</label>
      <input id="subject" maxlength="80" name="subject" size="20" type="text" value="GTPR Materials Request" hidden=""><br>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003nBJo">Reason:</label>
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
          <option value="Course Access" selected="">Course Access</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N0e000003De6b">Company:</label>
      <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
          <option value="Dalton" selected="">Dalton</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="type">Type</label>
      <select id="type" name="type">
          <option value="My Courses" selected="">My Courses</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="recordType">Case Record Type</label>
      <select id="recordType" name="recordType">
          <option value="0120e000000Dypi" selected="">CeriFi Support</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003PYuF">Review Type:</label>
      <select id="00N7V000003PYuF" name="00N7V000003PYuF" title="Review Type" required="">
          <option value="GTPR" selected="">GTPR</option> 
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003Dexf">Additional Information:</label>
      <textarea id="00N0e000003Dexf" name="00N0e000003Dexf" rows="3" type="text" wrap="soft"></textarea>
  </div>

  <div class="support-form__row support-form--half support-form--divider"><div></div></div>

  <div class="support-form__row support-form--half"> 
    <input id="update-shipping-address" maxlength="40" name="update-shipping-address" size="20" type="checkbox">
    <label for="update-shipping-address">Do you need to update your physical address?</label>
  </div>

  <div id="address-fields-divider" class="support-form__row support-form--half support-form--divider" hidden=""><div></div></div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex1">Mailing Street Address:</label>
      <textarea id="00N0e000003Dex1" name="00N0e000003Dex1" type="text" wrap="soft"></textarea>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex6">City:</label>
      <input id="00N0e000003Dex6" maxlength="50" name="00N0e000003Dex6" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexB">State:</label>
      <select id="00N0e000003DexB" name="00N0e000003DexB">
          <option value="">Select</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
      </select>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexG">Zip Code:</label>
      <input id="00N0e000003DexG" maxlength="50" name="00N0e000003DexG" size="20" type="text"><br>
  </div>
  
  <div class="support-form__row">
    <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback"></div>
    <br>
  </div>

  <div class="support-form__row support-form--submit">
    <input class="button leaf-button" type="submit" name="submit">
  </div>
</form>

<div class="support-form__error js-support-form__error" hidden="">Sorry, there was an error with your form submission. Please try again later.</div>
<div class="support-form__loading js-support-form__loading" hidden="">Sending...</div>
<div class="support-form__success js-support-form__success" hidden="">Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>

</div>`

const TAC_MATERIAL_REQUEST = `<div id="s-47ce86f3-9330-4bfc-8879-1072a00ba74a" class="shg-c  ">
<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.           -->
<!--  ----------------------------------------------------------------------  -->
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
/*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> 
<script type="text/javascript">
function recaptcha_callback(){
$('.button').prop("disabled", false);
}

$(document).ready(function() {
  $(".js-support-form__form").on("submit", function(event) {
    var response = $("#g-recaptcha-response").val();
    if(response === '') {
      event.preventDefault();
      alert("Please verify you are human!");
    }
  });
  
  const months = [3, 7, 11]; 

  var selectField = document.getElementById("00N0e000003DexL");
  var year = new Date().getFullYear();
  
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < months.length; j++) {
      var today = new Date();
      var optionValue = new Date(year,months[j]);
          if(today <  optionValue && selectField.length < 7){   
          var option = document.createElement("option");
          var formathDate = new Date(year,months[j]-1);
          var monthName = new Intl.DateTimeFormat("en-US", {month:"long"}).format(formathDate);
          option.text = monthName+" "+year;
          option.value = monthName+" "+ year;
          selectField.add(option);
      }
    }
    year++;
  }

  //only show address fields if Material Type is "Physical"
  var addressFields = $('#00N0e000003Dex1, #00N0e000003Dex6, #00N0e000003DexB, #00N0e000003DexG');
  var addressDividerDiv = $('#address-fields-divider');
  var selectMaterialType = $('#00N0e000003Dexa');
  selectMaterialType.change(function(){
    var type = $(this).val();
    if(type != 'Physical'){
      addressFields.val('').prop('required', false).hide().parent().hide();
      addressDividerDiv.hide();
      return;
    }

    addressFields.val('').prop('required', true).show().parent().show();
    addressDividerDiv.show();
  });
});

</script>
<style>
  .support-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .support-form__row {
    width: 100%;
    margin: 0 10px 30px;
  }

  .support-form__row,
  .support-form label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 700;
    color: #546277;
    text-transform: uppercase;
    width: 100%;
  }

  .support-form--lowercase{
    font-weight: 400;
    font-style: italic;
    text-transform: none;
  }
  
  .support-form--display{
    display: block;
  }

  .support-form input,
  .support-form textarea,
  .support-form select {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    display: block;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #707070;
  }

  .support-form textarea {
    min-height: 50px;
    height: auto;
  }
  
  .support-form__row.support-form--submit{
    margin-top: 30px;
    width: 100%;
  }
  
  .support-form__row.support-form--submit .button{
    width: calc(35% - 20px);
  }

  @media (min-width: 992px) {

    .support-form--half {
      width: calc(50% - 20px);
    }
    
    .support-form__row.support-form--submit .button{
      width: calc(20% - 20px);
    }
  }
      
  .support-form--hp{
    margin: 0;
    height: 0;
  }

  .hp{
    display: none !important;
  }
</style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<meta http-equiv="Content-type" content="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">

  <input type="hidden" name="orgid" value="00D60000000J4Mx">
  <input type="hidden" name="retURL" value="https://dalton-education.com/thank-you">

  <!--  ----------------------------------------------------------------------  -->
  <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
  <!--  these lines if you wish to test in debug mode.                          -->
  <!--  <input type="hidden" name="debug" value=1>                              -->
  <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
  <!--  ----------------------------------------------------------------------  -->
  <div class="support-form__row support-form--half">
      <label for="name">Contact Name</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="company">Company</label>
      <input id="company" maxlength="80" name="company" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half">
      <label for="email">Email</label>
      <input id="email" maxlength="80" name="email" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="phone">Phone</label>
      <input id="phone" maxlength="40" name="phone" size="20" type="text" required=""><br>
  </div>

  <div class="support-form__row support-form--half">
    <label for="00N0e000003Dexa">Digital materials?:</label>
    <select id="00N0e000003Dexa" name="00N0e000003Dexa" required="">
        <option value="">Select</option>
        <option value="Digital">Digital</option>
    </select>
  </div>

  <div id="address-fields-divider" class="support-form__row support-form--half support-form--divider" hidden=""><div></div></div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex1">Mailing Street Address:</label>
      <textarea id="00N0e000003Dex1" name="00N0e000003Dex1" type="text" wrap="soft"></textarea>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex6">City:</label>
      <input id="00N0e000003Dex6" maxlength="50" name="00N0e000003Dex6" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexB">State:</label>
      <select id="00N0e000003DexB" name="00N0e000003DexB">
          <option value="">Select</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
      </select>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexG">Zip Code:</label>
      <input id="00N0e000003DexG" maxlength="50" name="00N0e000003DexG" size="20" type="text"><br>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexL">CFP Exam Month?:</label>
      <select id="00N0e000003DexL" name="00N0e000003DexL" required="">
          <option value="">Select Month</option>
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexV">Where will you be attending?:</label>
      <select id="00N0e000003DexV" name="00N0e000003DexV" required="">
          <option value="">Select</option>
          <option value="Virtual">Virtual</option>
          <option value="Atlanta">Atlanta</option>
          <option value="Chicago">Chicago</option>
          <option value="LA/Irvine">LA/Irvine</option>
          <option value="Washington D.C.">Washington D.C.</option>
          <option value="New York City">New York City</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label class="form__label" for="subject">Subject</label>
      <input id="subject" maxlength="80" name="subject" size="20" type="text" value="GTPR Materials Request" hidden=""><br>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003nBJo">Reason:</label>
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
          <option value="Course Access" selected="">Course Access</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N0e000003De6b">Company:</label>
      <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
          <option value="Dalton" selected="">Dalton</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="type">Type</label>
      <select id="type" name="type">
          <option value="My Courses" selected="">My Courses</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="recordType">Case Record Type</label>
      <select id="recordType" name="recordType">
          <option value="0120e000000Dypi" selected="">CeriFi Support</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003PYuF">Review Type:</label>
      <select id="00N7V000003PYuF" name="00N7V000003PYuF" title="Review Type" required="">
          <option value="GTPR" selected="">GTPR</option> 
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003Dexf">Additional Information:</label>
      <textarea id="00N0e000003Dexf" name="00N0e000003Dexf" rows="3" type="text" wrap="soft"></textarea>
  </div>
  
  <div class="support-form__row">
    <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback"></div>
    <br>
  </div>

  <div class="support-form__row support-form--submit">
    <input class="button leaf-button" type="submit" name="submit" data-gtm-vis-recent-on-screen50865725_44="3857" data-gtm-vis-first-on-screen50865725_44="3857" data-gtm-vis-total-visible-time50865725_44="100" data-gtm-vis-has-fired50865725_44="1">
  </div>
</form>

<div class="support-form__error js-support-form__error" hidden="">Sorry, there was an error with your form submission. Please try again later.</div>
<div class="support-form__loading js-support-form__loading" hidden="">Sending...</div>
<div class="support-form__success js-support-form__success" hidden="">Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>

</div>`

const TAC_DALTON_REVIEW_MATERIAL_REQUEST = `<div id="s-47ce86f3-9330-4bfc-8879-1072a00ba74a" class="shg-c  ">
<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.           -->
<!--  ----------------------------------------------------------------------  -->
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
/*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> 
<script type="text/javascript">
function recaptcha_callback(){
$('.button').prop("disabled", false);
}

$(document).ready(function() {
  $(".js-support-form__form").on("submit", function(event) {
    var response = $("#g-recaptcha-response").val();
    if(response === '') {
      event.preventDefault();
      alert("Please verify you are human!");
    }
  });
  
  const months = [3, 7, 11]; 

  var selectField = document.getElementById("00N0e000003DexL");
  var year = new Date().getFullYear();
  
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < months.length; j++) {
      var today = new Date();
      var optionValue = new Date(year,months[j]);
      if(today <  optionValue && selectField.length < 7){   
          var option = document.createElement("option");
          var formathDate = new Date(year,months[j]-1);
          var monthName = new Intl.DateTimeFormat("en-US", {month:"long"}).format(formathDate);
          option.text = monthName+" "+year;
          option.value = monthName+" "+ year;
          selectField.add(option);
      }
    }
    year++;
  }

  //only show address fields if Material Type is "Physical"
  var addressFields = $('#00N0e000003Dex1, #00N0e000003Dex6, #00N0e000003DexB, #00N0e000003DexG');
  var addressDividerDiv = $('#address-fields-divider');
  var selectMaterialType = $('#00N0e000003Dexa');
  selectMaterialType.change(function(){
    var type = $(this).val();
    if(type != 'Physical'){
      addressFields.val('').prop('required', false).hide().parent().hide();
      addressDividerDiv.hide();
      return;
    }

    addressFields.val('').prop('required', true).show().parent().show();
    addressDividerDiv.show();
  });
});

</script>
<style>
  .support-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .support-form__row {
    width: 100%;
    margin: 0 10px 30px;
  }

  .support-form__row,
  .support-form label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 700;
    color: #546277;
    text-transform: uppercase;
    width: 100%;
  }

  .support-form--lowercase{
    font-weight: 400;
    font-style: italic;
    text-transform: none;
  }
  
  .support-form--display{
    display: block;
  }

  .support-form input,
  .support-form textarea,
  .support-form select {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    display: block;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #707070;
  }

  .support-form textarea {
    min-height: 50px;
    height: auto;
  }
  
  .support-form__row.support-form--submit{
    margin-top: 30px;
    width: 100%;
  }
  
  .support-form__row.support-form--submit .button{
    width: calc(35% - 20px);
  }

  @media (min-width: 992px) {

    .support-form--half {
      width: calc(50% - 20px);
    }
    
    .support-form__row.support-form--submit .button{
      width: calc(20% - 20px);
    }
  }
      
  .support-form--hp{
    margin: 0;
    height: 0;
  }

  .hp{
    display: none !important;
  }
</style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<meta http-equiv="Content-type" content="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">

  <input type="hidden" name="orgid" value="00D60000000J4Mx">
  <input type="hidden" name="retURL" value="https://dalton-education.com/thank-you">

  <!--  ----------------------------------------------------------------------  -->
  <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
  <!--  these lines if you wish to test in debug mode.                          -->
  <!--  <input type="hidden" name="debug" value=1>                              -->
  <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
  <!--  ----------------------------------------------------------------------  -->
  <div class="support-form__row support-form--half">
      <label for="name">Contact Name</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="company">Company</label>
      <input id="company" maxlength="80" name="company" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half">
      <label for="email">Email</label>
      <input id="email" maxlength="80" name="email" size="20" type="text" required="">
  </div>

  <div class="support-form__row support-form--half">
      <label for="phone">Phone</label>
      <input id="phone" maxlength="40" name="phone" size="20" type="text" required=""><br>
  </div>

  <div class="support-form__row support-form--half">
    <label for="00N0e000003Dexa">Digital materials?:</label>
    <select id="00N0e000003Dexa" name="00N0e000003Dexa" required="">
        <option value="">Select</option>
        <option value="Digital">Digital</option>
    </select>
  </div>

  <div id="address-fields-divider" class="support-form__row support-form--half support-form--divider" hidden=""><div></div></div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex1">Mailing Street Address:</label>
      <textarea id="00N0e000003Dex1" name="00N0e000003Dex1" type="text" wrap="soft"></textarea>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003Dex6">City:</label>
      <input id="00N0e000003Dex6" maxlength="50" name="00N0e000003Dex6" size="20" type="text">
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexB">State:</label>
      <select id="00N0e000003DexB" name="00N0e000003DexB">
          <option value="">Select</option>
          <option value="AL">Alabama</option>
          <option value="AK">Alaska</option>
          <option value="AZ">Arizona</option>
          <option value="AR">Arkansas</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DE">Delaware</option>
          <option value="DC">District Of Columbia</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="IA">Iowa</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="ME">Maine</option>
          <option value="MD">Maryland</option>
          <option value="MA">Massachusetts</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MS">Mississippi</option>
          <option value="MO">Missouri</option>
          <option value="MT">Montana</option>
          <option value="NE">Nebraska</option>
          <option value="NV">Nevada</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NY">New York</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VT">Vermont</option>
          <option value="VA">Virginia</option>
          <option value="WA">Washington</option>
          <option value="WV">West Virginia</option>
          <option value="WI">Wisconsin</option>
          <option value="WY">Wyoming</option>
      </select>
  </div>

  <div class="support-form__row support-form--half" hidden="">
      <label for="00N0e000003DexG">Zip Code:</label>
      <input id="00N0e000003DexG" maxlength="50" name="00N0e000003DexG" size="20" type="text"><br>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexL">CFP Exam Month?:</label>
      <select id="00N0e000003DexL" name="00N0e000003DexL" required="">
          <option value="">Select Month</option>
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003DexV">Where will you be attending?:</label>
      <select id="00N0e000003DexV" name="00N0e000003DexV" required="">
          <option value="">Select</option>
          <option value="Virtual">Virtual</option>
          <option value="Atlanta">Atlanta</option>
          <option value="Chicago">Chicago</option>
          <option value="LA/Irvine">LA/Irvine</option>
          <option value="Washington D.C.">Washington D.C.</option>
          <option value="New York City">New York City</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label class="form__label" for="subject">Subject</label>
      <input id="subject" maxlength="80" name="subject" size="20" type="text" value="TDR Materials Request" hidden=""><br>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003nBJo">Reason:</label>
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
          <option value="Course Access" selected="">Course Access</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N0e000003De6b">Company:</label>
      <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
          <option value="Dalton" selected="">Dalton</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="type">Type</label>
      <select id="type" name="type">
          <option value="My Courses" selected="">My Courses</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="recordType">Case Record Type</label>
      <select id="recordType" name="recordType">
          <option value="0120e000000Dypi" selected="">CeriFi Support</option>
      </select>
  </div>

  <div class="support-form__row" hidden="">
      <label for="00N7V000003PYuF">Review Type:</label>
      <select id="00N7V000003PYuF" name="00N7V000003PYuF" title="TDR Materials Request" required="">
          <option value="TDR" selected="">TDR</option> 
      </select>
  </div>

  <div class="support-form__row support-form--half">
      <label for="00N0e000003Dexf">Additional Information:</label>
      <textarea id="00N0e000003Dexf" name="00N0e000003Dexf" rows="3" type="text" wrap="soft"></textarea>
  </div>
  
  <div class="support-form__row">
    <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback"></div>
  </div>
  
  <div class="support-form__row support-form--submit">
    <input class="button leaf-button" type="submit" name="submit" data-gtm-vis-recent-on-screen50865725_44="13038" data-gtm-vis-first-on-screen50865725_44="13038" data-gtm-vis-total-visible-time50865725_44="100" data-gtm-vis-has-fired50865725_44="1">
  </div>
</form>

<div class="support-form__error js-support-form__error" hidden="">Sorry, there was an error with your form submission. Please try again later.</div>
<div class="support-form__loading js-support-form__loading" hidden="">Sending...</div>
<div class="support-form__success js-support-form__success" hidden="">Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>

</div>`

const CONTACT = `<!--  ----------------------------------------------------------------------  -->
<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.           -->
<!--  ----------------------------------------------------------------------  -->
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
 /*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script> 
<script type="text/javascript">
  /*
    SupportForm
   */

  var SupportForm = (function ($) {
    var m = (function () {
      var name = 'SupportForm';
      var version = '0.0.1';

      /*
        Local variable declarations
      */

      /*
        Handlers
      */
      var handlers = {
        disableOption: function ($option) {
          $option.prop('disabled', true).hide();
        },
        createSubmitButton: function($element){
           $submitFormRow = $element.find('.support-form--submit');
           $submitFormRow.append("<input id='formSubmitButton' class='button leaf-button' type='submit' name='submit' disabled>");
        },
        disableFormSubmit: function($element){
          $submitButton = $element.find('#formSubmitButton');
          $submitButton.attr("disabled", false);
          },
        disableSubmitButton: function($element){
          $submitButton = $element.find('.support-form--submit');
          $submitButton.attr("disabled", true);
          $submitButton.css("display", "none");
        },
        enableSubmitButton: function($element){
          $submitButton = $element.find('.support-form--submit');
          $submitButton.css("display", "block");
        },
        enableOption: function ($option) {
          $option.prop('disabled', false).show();
        },
        getConditionalFields: function ($element) {
          return $element.find('div[data-conditional-field]');
        },
        getConditionalParent: function ($element, $childField) {
          var name = $childField.data('conditional-parent');

          return $element.find('select[name="' + name + '"]');
        },
        getForm: function ($element) {
          return $element.find('.js-support-form__form');
        },
        getErrorMessage: function ($element) {
          return $element.find('.js-support-form__error');
        },
        getLoadingMessage: function ($element) {
          return $element.find('.js-support-form__loading');
        },
        getSuccessMessage: function ($element) {
          return $element.find('.js-support-form__success');
        },
        showErrorMessage: function ($element) {
          handlers.getLoadingMessage($element).hide();
          handlers.getSuccessMessage($element).hide();
          handlers.getForm($element).hide();
          handlers.getErrorMessage($element).show();
        },
        showLoadingMessage: function ($element) {
          handlers.getErrorMessage($element).hide();
          handlers.getForm($element).hide();
          handlers.getSuccessMessage($element).hide();
          handlers.getLoadingMessage($element).show();
        },
        showSuccessMessage: function ($element) {
          handlers.getLoadingMessage($element).hide();
          handlers.getErrorMessage($element).hide();
          handlers.getForm($element).hide();
          handlers.getSuccessMessage($element).show();
        },
         injectFormData: async function($element){

         },
        /*
        submitForm: function ($element) {
          var $form = handlers.getForm($element);
          var data = $form.serialize();
          var url = $form.attr('action');

          handlers.showLoadingMessage($element);

          $.ajax({
            headers: {
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Request-Method': 'POST',
              'accept': 'application/json',
            },
            data: data,
            error: function(jqHXR, textStatus, errorThrown){
              handlers.showErrorMessage($element);
            },
            success: function (data, textStatus, jqHXR) {
              handlers.showSuccessMessage($element);
            },
            method: "POST",
            url: url,
            crossOrigin: true,
            crossDomain: true,
          });
        },
        */
        updateChildField: function ($element, $childField) {
          // Reset the child field's value
          $childField.find('select').val('');

          var $parentField = handlers.getConditionalParent($element, $childField);
          var parentValue = $parentField.find(':selected').data('toggle');

          var showChildField = false;
          var $options = $childField.find('option');
          var $scheduleButton = $childField.find('a');
          $options.each(function () {
            var $option = $(this);

            if ($option.attr('value')) {
              // If option is nonempty and matches parentValue, enable it
              if ($option.data('conditional-value') == parentValue) {
                handlers.enableOption($option);
                showChildField = true;
              }
              // If option is nonempty and doesn't match parentValue, disable it
              else {
                handlers.disableOption($option);
              }
            }
            // If option is empty, leave it enabled.
            else {
              handlers.enableOption($option);
            }
          });

          $scheduleButton.each(function (){
            var $button = $(this);

            if($button.attr('href')){
              if($button.data('conditional-value') == parentValue){
                handlers.enableOption($button);
                showChildField = true;
                handlers.disableSubmitButton($element);
              }
              else{
                handlers.disableOption($button);
                handlers.enableSubmitButton($element);
              }
            }
            else{
              handlers.enableOption($button);
              handlers.enableSubmitButton($element);
            }
          });

          //toggle select field visibility and required attribute
          if(showChildField){
            $childField.show();
            $childField.find('select').attr('required', 'required');
          } else {
            $childField.hide();
            $childField.find('select').removeAttr('required');
          }

          //reset and hide remaining select fields
          $childField.nextAll('[data-conditional-field]').each(function(){
            if($(this).find('select').length){
              $(this).find('select').val('').removeAttr('required');
              $(this).hide();
            }
          });
        },
      };

      /*
        List handlers that should be
        accessible in the global namespace
      */
      var publicHandlers = [

      ];

      /*
        Events
      */
      var events = {
        /*
        formSubmit: function () {
          $('.js-support-form').each(function () {
            var $element = $(this);
            var $form = handlers.getForm($('.js-support-form'));
            $form.on('submit', function (event) {
              event.preventDefault();
              handlers.submitForm($element);
            });  
          });
        },
        */
        pageLoad: function () {

        },
        parentSelectChange: function () {
          $('form[data-support-form]').each(function () {
            var $element = $(this);
            handlers.createSubmitButton($element);
            handlers.disableFormSubmit($element);
            handlers.getConditionalFields($element).each(function () {
              var $childField = $(this);
              var $parentField = handlers.getConditionalParent($element, $childField);

              $parentField.on('change', function () {
                handlers.updateChildField($element, $childField);
              });
            });
          });
        },
      //   onSubmitForm: function(){
      //     let formElement = document.querySelector("form[data-support-form]");
      //     $form = $('form[data-support-form]');
      //     $submitButton = $form.find('#formSubmitButton');

      //     $submitButton.on("click", async function(){
      //         if(formElement.classList.contains("js-support-form--error")){
      //             await handlers.injectFormData($form);
      //         }
      //     })
      //   }
      };

      /*
        List events in the order they
        should be bound. Any unlisted
        events will be registered last.
      */
      var eventOrder = [
        'pageLoad',
      ];

      /************************************************************************************
        DON'T EDIT BELOW THIS LINE
      ************************************************************************************/

      var bindEvents = function () {
        var eventNames = Object.keys(events);
        for (var i = 0; i < eventNames.length; i++) {
          var eventName = eventNames[i];
          if (eventOrder.indexOf(eventName) < 0) {
            eventOrder.push(eventName);
          }
        }

        for (var i = 0; i < eventOrder.length; i++) {
          var eventName = eventOrder[i];
          var event = events[eventName];

          if (event !== undefined) {
            if (typeof(event) === 'function') {
              try {
                event();
              }
              catch(e) {
                console.error('Event binding failed: ' + eventName);
                console.error(e);
              }
            }
            else {
              console.error('Event is not a function: ' + eventName);
            }
          }
          else {
            console.error('Event is undefined: ' + eventName);
          }
        }
      };

      var registerPublicHandlers = function () {
        for (var i = 0; i < publicHandlers.length; i++) {
          var handlerName = publicHandlers[i];
          var handler = handlers[handlerName];
          if (handler !== undefined) {
            if (typeof(handler) === 'function') {
              interface[handlerName] = handler;
            }
            else {
              console.error('Handler is not a function: ' + handlerName);
            }
          }
          else {
            console.error('Handler is undefined: ' + handlerName);
          }
        }
      };

      var interface = {
        _init_: function () {
          registerPublicHandlers();
          bindEvents();
          delete interface._init_;
        },
        toString: function () {
          return name + ' (version ' + version + ')';
        },
      };

      return interface;
    }());

    $(function () {
      // Error-safe initialization
      try {
        m._init_();
      }
      catch (e) {
        console.error('Module failed to initialize: ' + m);
        console.error(e);
      }
    });

    return m;
  }(jQuery));
  </script>
<script type="text/javascript">
function recaptcha_callback(){
$('.button').prop("disabled", false);
}

 $(document).ready(function() {
    $(".js-support-form__form").on("submit", function(event) {
      var response = $("#g-recaptcha-response").val();
      if(response === '') {
        event.preventDefault();
        alert("Please verify you are human!");
      } else {
        var input = $('#supportFormInput');
        input.attr('value', '00D'+ 6 * 10 ** 7 +'J4Mx');
        input.attr('name', 'or'+'gid');
      }
    });
 });

</script>
<style>
    .support-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .support-form__row {
      width: 100%;
      margin: 0 10px 30px;
    }

    .support-form__row,
    .support-form label {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      line-height: 1.5rem;
      font-weight: 700;
      color: #546277;
      text-transform: uppercase;
      width: 100%;
    }

    .support-form--lowercase{
      font-weight: 400;
      font-style: italic;
      text-transform: none;
    }

    .support-form--display{
      display: block;
    }

    .support-form input,
    .support-form textarea,
    .support-form select {
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      display: block;
      font-size: 16px;
      padding: 10px;
      border: 1px solid #707070;
    }

    .support-form textarea {
      min-height: 50px;
      height: auto;
    }

    .support-form__row.support-form--submit{
      margin-top: 30px;
      width: 100%;
    }

    .support-form__row.support-form--submit .button{
      width: calc(35% - 20px);
    }

    @media (min-width: 992px) {

      .support-form--email,
      .support-form--phone,
      .support-form--company,
      .support-form--subject,
      .support-form--type,
      .support-form--casereason,
      .support-form--subreason,
      .support-form--secondary-subreason,
      .support-form--tertiary-subreason,
      .support-form--priority,
      .support-form--case-record {
        width: calc(50% - 20px);
      }

      .support-form__row.support-form--submit .button{
        width: calc(20% - 20px);
      }
    }

    .support-form--hp{
      margin: 0;
      height: 0;
    }

    .hp{
      display: none !important;
    }
  </style>
  <form class="support-form js-support-form__form" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST" data-support-form>
    <div class="support-form__row">
      <input type=hidden id="supportFormInput">
      <input type=hidden name="retURL" value="https://dalton-education.com/thank-you">
    </div>
    <div class="support-form__row support-form--hp">
      <label for="00N7V000003nGO5" class="hp">Contact Name*</label>
      <input id="00N7V000003nGO5" maxlength="255" name="00N7V000003nGO5" size="20" type="text" class="hp" title="name"/><br>
    </div>
    <div class="support-form__row support-form--contact-name">
      <label for="name">Contact Name*</label>
      <input id="name" maxlength="80" name="name" size="20" type="text" required/><br>
    </div>
    <div class="support-form__row support-form--email">
      <label for="email">Email*</label>
      <input id="email" maxlength="80" name="email" size="20" type="text" required/><br>
    </div>
    <div class="support-form__row support-form--phone">
      <label for="phone">Phone*</label>
      <input id="phone" maxlength="40" name="phone" size="20" type="text" required/><br>
    </div>
    <div class="support-form__row" hidden>
      <label for="00N7V000003nGOA">Description</label>
      <textarea id="00N7V000003nGOA" name="00N7V000003nGOA" rows="3" type="text" wrap="soft" title="description"></textarea><br>
    </div>
    <div class="support-form__row support-form--company" hidden>
      Company*
      <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
        <option value="Dalton" selected>Dalton</option>
      </select><br>
    </div>
    <div class="support-form__row support-form--type" data-requesttyppe>
      <label for="type">What do you need help with?*</label>
      <select id="type" name="type" data-request-type required>
        <option value="">--None--</option>
        <option data-toggle="My Account" value="My Account">My Account</option>
        <option data-toggle="My Courses" value="My Courses">My Courses</option>
        <option data-toggle="My Orders" value="My Orders">My Orders</option>
        <option data-toggle="Other" value="Other">Other</option>
      </select><br>
    </div>
    <div class="support-form__row support-form--casereason" data-conditional-field data-conditional-parent="type" hidden>
      Reason*
      <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
        <!--Account reasons-->
        <option value="">--None--</option>
        <option data-conditional-value="My Account" data-toggle="MyAccountBilling" value="Billing">Billing</option>
        <option data-conditional-value="My Account" data-toggle="MyAccountPayments" value="Payments">Payments</option>
        <option data-conditional-value="My Account" data-toggle="MyAccountOther" value="Other">Other</option>

        <!--Courses reasons-->
        <option data-conditional-value="My Courses" data-toggle="MyCoursesCourse" value="Course Access">Course Access</option>
        <option data-conditional-value="My Courses" data-toggle="MyCoursesSupport" value="Instructor Support">Instructor Support</option>
        <option data-conditional-value="My Courses" data-toggle="MyCoursesResetCourseAccount" value="Reset My Course Account">Reset My Course Account</option>
        <option data-conditional-value="My Courses" data-toggle="MyCoursesTechnicalIssues" value="Technical Issues">Technical Issues</option>
        <option data-conditional-value="My Courses" data-toggle="MyCoursesTextbookRequest" value="Textbook Request">Textbook Request</option>
        <option data-conditional-value="My Courses" data-toggle="MyCoursesOther" value="Other">Other</option>

        <!--Orders reasons-->
        <option data-conditional-value="My Orders" data-toggle="MyOrdersCancel" value="Cancel my order">Cancel my order</option>
        <option data-conditional-value="My Orders" data-toggle="MyOrdersChange" value="Change my order">Change my order</option>
        <option data-conditional-value="My Orders" data-toggle="MyOrdersEnroll" value="Enroll in a live classroom">Enroll in a live classroom</option>
        <option data-conditional-value="My Orders" data-toggle="MyOrdersTracking" value="Need tracking information">Need tracking information</option>
        <option data-conditional-value="My Orders" data-toggle="MyOrdersShipping" value="Shipping">Shipping</option>
        <option data-conditional-value="My Orders" data-toggle="MyOrdersOther" value="Other">Other</option>

        <!--Other reasons-->
        <option data-conditional-value="Other" data-toggle="OtherFeedback" value="Product Feedback">Product Feedback</option>
        <option data-conditional-value="Other" data-toggle="OtherSales" value="Sales">Sales</option>
        <option data-conditional-value="Other" data-toggle="OtherOther" value="Other">Other</option>
      </select><br>
    </div>

      <!--Case Primary SubReasons
          Account subreasons-->
    <div class="support-form__row support-form--subreason" data-conditional-field data-conditional-parent="00N7V000003nBJo" hidden>
      Case Subreason*
      <select id="00N0e000003De2F" name="00N0e000003De2F" title="Case Subreason">
        <option value="">--None--</option>
        <!--Account billing subreason-->
        <option data-conditional-value="MyAccountBilling" data-toggle="MyAccountBillingQuestion" value="Question about my order reciept">Question about my order reciept</option>
        <option data-conditional-value="MyAccountBilling" data-toggle="MyAccountBillingUpdateContact" value="Update my contact info">Update my contact info</option>

        <!--Account Payments subreason-->
        <option data-conditional-value="MyAccountPayments" data-toggle="IssuePayment" value="Issue with payment">Issue with payment</option>
        <option data-conditional-value="MyAccountPayments" data-toggle="RecieptReimbursement" value="Receipt/Reimbursement">Receipt/Reimbursement</option>
        <option data-conditional-value="MyAccountPayments" data-toggle="RefundRequest" value="Refund request">Refund request</option>
        <option data-conditional-value="MyAccountPayments" data-toggle="UpdatePayment" value="Update payment method">Update payment method</option>

        <!--Courses Course subreasons-->
        <option data-conditional-value="MyCoursesCourse" data-toggle="CannotLogin" value="I cannot login">I cannot login</option>
        <option data-conditional-value="MyCoursesCourse" data-toggle="ForgotPassword" value="I forgot my password or username">I forgot my password or username</option>
        <option data-conditional-value="MyCoursesCourse" data-toggle="MissingCourseContent" value="I am missing course content">I am missing course content</option>
        <option data-conditional-value="MyCoursesCourse" data-toggle="MissingLiveClasses" value="I am missing live classes">I am missing live classes</option>
        <option data-conditional-value="MyCoursesCourse" data-toggle="ExtendCourse" value="I want to extend my course">I want to extend my course</option>

        <!--Courses Support subreasons-->
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportAudioReviews" value="Audio Reviews">Audio Reviews</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportCFPBoardInfo" value="CFP Board Information">CFP Board Information</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupporteBook" value="eBook">eBook</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportFlashcards" value="Flashcards">Flashcards</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportOnlineCourse" value="Online Course">Online Course</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportQuickSheets" value="Quicksheets">Quicksheets</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportTestbank" value="Testbank">Testbank</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportTextbook" value="Textbook">Textbook</option>
        <option data-conditional-value="MyCoursesSupport" data-toggle="MyCoursesSupportOther" value="Other">Other</option>

        <!--Courses Technical Issues subreasons-->
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueAudioReviews" value="Audio Reviews">Audio Reviews</option>
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueeBook" value="eBook">eBook</option>
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueFlashcards" value="Flashcards">Flashcards</option>
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueOnlineCourse" value="Online Course">Online Course</option>
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueTestbank" value="Testbank">Testbank</option>
        <option data-conditional-value="MyCoursesTechnicalIssues" data-toggle="MyCoursesTechnicalIssueOther" value="Other">Other</option>

        <!--Orders Shipping subreasons -->
        <option data-conditional-value="MyOrdersShipping" data-toggle="MyOrdersShippingInternationalShipping" value="International shipping">International shipping</option>
        <option data-conditional-value="MyOrdersShipping" data-toggle="MyOrdersShippingUpdateShippingAddress" value="Update my shipping address">Update my shipping address</option>

        <!--Other Product Feedback subreasons -->
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackAudioReviews" value="AudioReviews">Audio Reviews</option>     
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackeBook" value="eBook">eBook</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackFlashcards" value="Flashcards">Flashcards</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackOnlineCourse" value="Online Course">Online Course</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackQuicksheets" value="Quicksheets">Quicksheets</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackTestbank" value="Testbank">Testbank</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackTextbook" value="Textbook">Textbook</option>
        <option data-conditional-value="OtherFeedback" data-toggle="OtherFeedbackOther" value="Other">Other</option>
       </select><br>
    </div>

    <!--Secondary Subreason
        Courses subreason -->
    <div class="support-form__row support-form--secondary-subreason" data-conditional-field  data-conditional-parent="00N0e000003De2F" hidden>
      Case Subreason*
      <select id="00N7V000003mq7I" name="00N7V000003mq7I" title="Choose a course">
        <option value="">--None--</option>
        <!--Support Audio Reviews 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportAudioReviews" data-toggle="CoursesSupportAudioReviewsIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportAudioReviews" data-toggle="CoursesSupportAudioReviewsIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportAudioReviews" data-toggle="CoursesSupportAudioReviewsSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportAudioReviews" data-toggle="CoursesSupportAudioReviewsWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportAudioReviews" data-toggle="CoursesSupportAudioReviewsOther" value="Other">Other</option>

        <!--Support CFP Board Info 2nd subreason-->
        <option data-conditional-value="MyCoursesSupportCFPBoardInfo" data-toggle="CoursesSupportCFPBoardInfoIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportCFPBoardInfo" data-toggle="CoursesSupportCFPBoardInfoIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportCFPBoardInfo" data-toggle="CoursesSupportCFPBoardInfoSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportCFPBoardInfo" data-toggle="CoursesSupportCFPBoardInfoWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportCFPBoardInfo" data-toggle="CoursesSupportCFPBoardInfoOther" value="Other">Other</option>

        <!--Support eBook 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupporteBook" data-toggle="CoursesSupporteBookIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupporteBook" data-toggle="CoursesSupporteBookIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupporteBook" data-toggle="CoursesSupporteBookSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupporteBook" data-toggle="CoursesSupporteBookWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupporteBook" data-toggle="CoursesSupporteBookOther" value="Other">Other</option>

        <!--Support Flash Cards 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportFlashcards" data-toggle="CoursesSupportFlashcardsIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportFlashcards" data-toggle="CoursesSupportFlashcardsIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportFlashcards" data-toggle="CoursesSupportFlashcardsSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportFlashcards" data-toggle="CoursesSupportFlashcardsWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportFlashcards" data-toggle="CoursesSupportFlashcardsOther" value="Other">Other</option>

        <!--Support Online Courses 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportOnlineCourse" data-toggle="CoursesSupportOnlineCourseIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportOnlineCourse" data-toggle="CoursesSupportOnlineCourseIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportOnlineCourse" data-toggle="CoursesSupportOnlineCourseSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportOnlineCourse" data-toggle="CoursesSupportOnlineCourseWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportOnlineCourse" data-toggle="CoursesSupportOnlineCourseOther" value="Other">Other</option>

        <!--Support Quicksheets 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportQuickSheets" data-toggle="CoursesSupportQuickSheetsIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportQuickSheets" data-toggle="CoursesSupportQuickSheetsIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportQuickSheets" data-toggle="CoursesSupportQuickSheetsSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportQuickSheets" data-toggle="CoursesSupportQuickSheetsWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportQuickSheets" data-toggle="CoursesSupportQuickSheetsOther" value="Other">Other</option>

        <!--Support Testbank 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportTestbank" data-toggle="CoursesSupportTestbankIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportTestbank" data-toggle="CoursesSupportTestbankIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportTestbank" data-toggle="CoursesSupportTestbankSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportTestbank" data-toggle="CoursesSupportTestbankWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportTestbank" data-toggle="CoursesSupportTestbankOther" value="Other">Other</option>

        <!--Support Textbook 2nd Subreason-->
        <option data-conditional-value="MyCoursesSupportTextbook" data-toggle="CoursesSupportTextbookIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportTextbook" data-toggle="CoursesSupportTextbookIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportTextbook" data-toggle="CoursesSupportTextbookSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportTextbook" data-toggle="CoursesSupportTextbookWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportTextbook" data-toggle="CoursesSupportTextbookOther" value="Other">Other</option>

        <option data-conditional-value="MyCoursesSupportOther" data-toggle="CoursesSupportOtherIncorrectCalculation" value="Incorrect Calculation">Incorrect Calculation</option>
        <option data-conditional-value="MyCoursesSupportOther" data-toggle="CoursesSupportOtherIncorrectAnswer" value="Incorrect Answer">Incorrect Answer</option>
        <option data-conditional-value="MyCoursesSupportOther" data-toggle="CoursesSupportOtherSomethingMissing" value="Something is missing">Something is missing</option>
        <option data-conditional-value="MyCoursesSupportOther" data-toggle="CoursesSupportOtherWrongExplanation" value="Wrong Explanation">Wrong explanation</option>
        <option data-conditional-value="MyCoursesSupportOther" data-toggle="CoursesSupportOtherOther" value="Other">Other</option>

        <!--Support Reset My Course Account Audio Reviews 2nd Subreason-->
        <option data-conditional-value="MyCoursesTechnicalIssueAudioReviews" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueAudioReviews" value="Other">Other</option>

        <option data-conditional-value="MyCoursesTechnicalIssueeBook" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueeBook" value="Other">Other</option>

        <option data-conditional-value="MyCoursesTechnicalIssueFlashcards" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueFlashcards" value="Other">Other</option>

        <option data-conditional-value="MyCoursesTechnicalIssueOnlineCourse" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueOnlineCourse" value="Other">Other</option>

        <option data-conditional-value="MyCoursesTechnicalIssueTestbank" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueTestbank" value="Other">Other</option>

        <option data-conditional-value="MyCoursesTechnicalIssueOther" value="Video/player bugs">Video/player bugs</option>
        <option data-conditional-value="MyCoursesTechnicalIssueOther" value="Other">Other</option>

        <!--Other Product Feedback 2nd subreason-->
        <option data-conditional-value="OtherFeedbackAudioReviews" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackAudioReviews" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackeBook" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackeBook" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackFlashcards" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackFlashcards" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackOnlineCourse" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackOnlineCourse" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackQuicksheets" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackQuicksheets" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackTestbank" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackTestbank" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackTextbook" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackTextbook" value="Suggest new feature">Suggest new feature</option>

        <option data-conditional-value="OtherFeedbackOther" value="Suggest new content">Suggest new content</option>
        <option data-conditional-value="OtherFeedbackOther" value="Suggest new feature">Suggest new feature</option>

        <!--Other Sales 2nd Sub reason-->
        <option data-conditional-value="OtherSalesCurrentStudent" data-toggle="OtherSalesCurrentStudentMoreInfo" value="I want more information">I want more information</option>
        <option data-conditional-value="OtherSalesCurrentStudent" data-toggle="OtherSalesCurrentStudentReadyToBuy" value="I am ready to buy">I am ready to buy</option>
      </select><br>
    </div>

    <!--Other secondary subreasons-->
    <div class="support-form__row support-form--secondary-subreason"  data-conditional-field data-conditional-parent="00N7V000003nBJo" hidden >
      <a data-conditional-value="OtherSales" class="button leaf-button" href="/schedule-a-call">
        Please Schedule a Call
      </a>
    </div>

    <!--Priority field-->
    <div class="support-form__row support-form--priority" hidden>
      <label for="priority">Priority</label>
      <select id="priority" name="priority">
        <option value="Medium" selected>Medium</option>
      </select><br>
    </div>
    <div class="form__item" hidden>
        <label class="form__label" for="subject">Subject</label>
        <input id="subject" maxlength="80" name="subject" size="20" type="text" value="Support Request" />
      </div>
    <div class="support-form__row support-form--case-record" hidden>
      <label for="recordType">Case Record Type</label><select id="recordType" name="recordType">
        <option value="0120e000000Dypi" selected>CeriFi Support</option>
      </select><br>
    </div>

    <div class="support-form__row support-form--comment" data-comments-case-reason>
      <label for="description">
        Comments: <span class="support-form--lowercase">(Please include additional details for your request or issue, including page number and/or URL, if applicable.)</span>
      </label>
      <textarea name="description" type="text" wrap="soft"></textarea>
      <span class="required_field" style="
          font-weight: normal;
      ">* Required Field</span>
      <br>
    </div>
    <div class="support-form__row">
      <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp" data-callback="recaptcha_callback"></div>
      <br>
    </div>
    <div class="support-form__row support-form--submit">
      <noscript>
        <p style="color: #e31c3d">Please Enable JavaScript to continue</p>
      </noscript>
    </div>

  </form>

  <div class="support-form__error js-support-form__error" hidden>Sorry, there was an error with your form submission. Please try again later.</div>
  <div class="support-form__loading js-support-form__loading" hidden>Sending...</div>
  <div class="support-form__success js-support-form__success" hidden>Thank you for contacting Dalton Education. A member of our team will contact you shortly.</div>`

const EDWARD_JONES_REQUEST = `<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
    /*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script>
<script type="text/javascript">
    function recaptcha_callback() {
        $('.button').prop("disabled", false);
    }

    $(document).ready(function () {
        $(".js-support-form__form").on("submit", function (event) {
            var response = $("#g-recaptcha-response").val();
            if (response === '') {
                event.preventDefault();
                alert("Please verify you are human!");
            }
        });
    });

</script>

<style>
    .support-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .support-form__row {
        width: 100%;
        margin: 0 10px 30px;
    }

    .support-form__row,
    .support-form label {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 1.5rem;
        font-weight: 700;
        color: #546277;
        text-transform: uppercase;
        width: 100%;
    }

    .support-form--lowercase {
        font-weight: 400;
        font-style: italic;
        text-transform: none;
    }

    .support-form--display {
        display: block;
    }

    .support-form input,
    .support-form textarea,
    .support-form select {
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        display: block;
        font-size: 16px;
        padding: 10px;
        border: 1px solid #707070;
    }

    .support-form textarea {
        min-height: 50px;
        height: auto;
    }

    .support-form__row.support-form--submit {
        margin-top: 30px;
        width: 100%;
    }

    .support-form__row.support-form--submit .button {
        width: calc(35% - 20px);
    }

    @media (min-width: 992px) {

        .support-form--half {
            width: calc(50% - 20px);
        }

        .support-form__row.support-form--submit .button {
            width: calc(20% - 20px);
        }
    }

    .support-form--hp {
        margin: 0;
        height: 0;
    }

    .hp {
        display: none !important;
    }

    #update-shipping-address {
        display: inline;
        width: unset;
        margin-right: 5px;
    }
</style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form"
    action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D60000000J4Mx" method="POST">

    <input type=hidden name="orgid" value="00D60000000J4Mx">
    <input type=hidden name="retURL" value="https://dalton-education.com/thank-you">

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
    <!--  ----------------------------------------------------------------------  -->
    <div class="support-form__row support-form--half">
        <label for="name">Contact Name</label>
        <input id="name" maxlength="80" name="name" size="20" type="text" required=""/>
        <br>
    </div>

    <div class="support-form__row support-form--half">
        <label for="email">Email</label>
        <input id="email" maxlength="80" name="email" size="20" type="text" required="" placeholder="Please enter your Edward Jones email address"/>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        Program Type:
        <input id="00NVX000000bXWU" maxlength="255" name="00NVX000000bXWU" size="20" type="text" value="FFP" />
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="subject">Subject</label>
        <input id="subject" maxlength="80" name="subject" size="20" type="text" value="FFP Course Enrollment" />
        <br>
    </div>

    <div class="support-form__row" hidden="">
        Company:
        <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
            <option value="Dalton">Dalton</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="priority">Priority</label>
        <select id="priority" name="priority">
            <option value="High">High</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="type">Type</label>
        <select id="type" name="type">
            <option value="My Courses">My Courses</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        Reason:
        <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
            <option value="Course Access">Course Access</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="recordType">Case Record Type</label>
        <select id="recordType" name="recordType">
            <option value="0120e000000Dypi">CeriFi Support</option>
        </select>
        <br>
    </div>

    <div class="support-form__row">
        <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp"
            data-callback="recaptcha_callback"></div>
        <br>
    </div>

    <div class="support-form__row support-form--submit">
        <input type="submit" name="submit">
    </div>
</form>` 
const VANGUARD_REQUEST = `<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<script>
    /*function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);*/
</script>
<script type="text/javascript">
    function recaptcha_callback() {
        $('.button').prop("disabled", false);
    }

    $(document).ready(function () {
        $(".js-support-form__form").on("submit", function (event) {
            var response = $("#g-recaptcha-response").val();
            if (response === '') {
                event.preventDefault();
                alert("Please verify you are human!");
            }
        });
    });

</script>

<style>
    .support-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .support-form__row {
        width: 100%;
        margin: 0 10px 30px;
    }

    .support-form__row,
    .support-form label {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        line-height: 1.5rem;
        font-weight: 700;
        color: #546277;
        text-transform: uppercase;
        width: 100%;
    }

    .support-form--lowercase {
        font-weight: 400;
        font-style: italic;
        text-transform: none;
    }

    .support-form--display {
        display: block;
    }

    .support-form input,
    .support-form textarea,
    .support-form select {
        width: 100%;
        font-family: 'Montserrat', sans-serif;
        display: block;
        font-size: 16px;
        padding: 10px;
        border: 1px solid #707070;
    }

    .support-form textarea {
        min-height: 50px;
        height: auto;
    }

    .support-form__row.support-form--submit {
        margin-top: 30px;
        width: 100%;
    }

    .support-form__row.support-form--submit .button {
        width: calc(35% - 20px);
    }

    @media (min-width: 992px) {

        .support-form--half {
            width: calc(50% - 20px);
        }

        .support-form__row.support-form--submit .button {
            width: calc(20% - 20px);
        }
    }

    .support-form--hp {
        margin: 0;
        height: 0;
    }

    .hp {
        display: none !important;
    }

    #update-shipping-address {
        display: inline;
        width: unset;
        margin-right: 5px;
    }
</style>

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <META> element to your page <HEAD>.      -->
<!--  If necessary, please modify the charset parameter to specify the        -->
<!--  character set of your HTML page.                                        -->
<!--  ----------------------------------------------------------------------  -->

<META HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8">

<!--  ----------------------------------------------------------------------  -->
<!--  NOTE: Please add the following <FORM> element to your page.             -->
<!--  ----------------------------------------------------------------------  -->

<form class="support-form js-support-form__form"
    action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D60000000J4Mx" method="POST">

    <input type=hidden name="orgid" value="00D60000000J4Mx">
    <input type=hidden name="retURL" value="https://dalton-education.com/thank-you">

    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail" value="courtney@cerifi.com">     -->
    <!--  ----------------------------------------------------------------------  -->
    <div class="support-form__row support-form--half">
        <label for="name">Contact Name</label>
        <input id="name" maxlength="80" name="name" size="20" type="text" required=""/>
        <br>
    </div>

    <div class="support-form__row support-form--half">
        <label for="email">Email</label>
        <input id="email" maxlength="80" name="email" size="20" type="text" required="" placeholder="Please enter your Vanguard email address"/>
        <br>
    </div>
    <div class="support-form__row support-form--half">
        <label for="phone">Phone</label>
        <input id="phone" maxlength="40" name="phone" size="20" type="text" required="true">
        <br>
     </div>
    <div class="support-form__row" hidden="">
        Program Type:
        <input id="00NVX000000bXWU" maxlength="255" name="00NVX000000bXWU" size="20" type="text" value="SPFP" />
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="subject">Subject</label>
        <input id="subject" maxlength="80" name="subject" size="20" type="text" value="SPFP Course Enrollment" />
        <br>
    </div>

    <div class="support-form__row" hidden="">
        Company:
        <select id="00N0e000003De6b" name="00N0e000003De6b" title="Company">
            <option value="Dalton">Dalton</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="priority">Priority</label>
        <select id="priority" name="priority">
            <option value="High">High</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="type">Type</label>
        <select id="type" name="type">
            <option value="My Courses">My Courses</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        Reason:
        <select id="00N7V000003nBJo" name="00N7V000003nBJo" title="Reason">
            <option value="Course Access">Course Access</option>
        </select>
        <br>
    </div>

    <div class="support-form__row" hidden="">
        <label for="recordType">Case Record Type</label>
        <select id="recordType" name="recordType">
            <option value="0120e000000Dypi">CeriFi Support</option>
        </select>
        <br>
    </div>

    <div class="support-form__row">
        <div class="g-recaptcha" data-sitekey="6LfkQyIlAAAAAK4-VLdAuH6An9dlC2wnVxDWrfZp"
            data-callback="recaptcha_callback"></div>
        <br>
    </div>

    <div class="support-form__row support-form--submit">
        <input type="submit" name="submit">
    </div>
</form>` 
export const FORMS = Object.freeze({
  EXAM_RESULT,
  TDR_MATERIAL_REQUEST,
  GUARANTEE_TO_PASS_MATERIAL_REQUEST,
  TAC_DALTON_REVIEW_MATERIAL_REQUEST,
  TAC_MATERIAL_REQUEST,
  CONTACT,
  EDWARD_JONES_REQUEST,
  VANGUARD_REQUEST
})
